<template>
  <v-dialog
    v-model="show"
    width="auto"
    @click:outside="init()"
    @keydown="$event.key === 'Escape' ? init() : ''"
  >
    <v-card class="log-wrap">
      <p class="tit">
        {{ batch != null ? `메모일괄등록(${batch.length})` : '고객 메모' }}
        <span class="sub" v-if="batch == null">{{ target.name }}</span>
        <v-chip class="float-right white--text"
          label
          link
          depressed
          color="purple"
          @click="deleteAllMemo()"
          v-if="checkFuncPermission('memoAllDelete') && batch == null"
        >
          메모전체삭제
        </v-chip>
        <v-chip class="float-right mr-1"
          label
          link
          depressed
          color="error"
          @click="deleteSelectedMemo()"
          v-if="batch == null"
        >
          선택메모삭제
        </v-chip>
        <v-chip class="float-right mr-1"
          label
          link
          depressed
          color="yellow"
          @click="showStatusDialog = true"
          v-if="batch == null"
        >
          상태변경
        </v-chip>
      </p>

      <!-- 상태변경 다이얼로그 -->
      <v-dialog v-model="showStatusDialog" max-width="150" class="status-dialog">
        <v-card>
          <v-card-title class="justify-center">
            상태 변경
          </v-card-title>
          <!-- <v-card-text>
            <v-list>
              <v-list-item-group v-model="selectedStates" multiple>
                <v-list-item
                  v-for="state in states"
                  :key="state.seq"
                  class="options"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="primary"
                        v-model="state.bool"
                      />
                    </v-list-item-action>
                    <v-list-item-title>{{ state.name }}</v-list-item-title>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text> -->
          <v-card-text>
            <div class="pa-2">
              <div
                v-for="state in states"
                :key="state.seq"
                class="d-flex align-center mb-2"
              >
                <v-checkbox
                  v-model="state.bool"
                  :label="state.name"
                  color="primary"
                  hide-details
                  dense
                />
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              @click="updateCustomerStatus"
              :loading="loading"
            >
              적용하기
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 메모 목록 -->
      <div v-if="batch != null"></div>
      <div
        class="log"
        v-else-if="data != null && data.length > 0"
      >
        <div
          class="log-row"
          v-for="(row, index) in data"
          :key="index"
        >
          <span class="log-span memo-span d-flex align-center"
           :class="row.category">
          <v-checkbox
            v-model="checked[index]"
          >
          </v-checkbox>
           {{ row.memo }}</span>
          <div class="info-box d-flex">
            <div>
              <p>{{ makeCreatorToFullName(row.creator) }}</p>
              <p class="date">{{ time.makeLocalTime(row.date, 'sec') }}</p>
            </div>
            <div class="d-flex align-center">
              <v-tooltip
                top
                color="#607D8B"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="ml-1"
                    v-on="on"
                    v-bind="attrs"
                    @click="delMemo(target, index)"
                  >
                    <v-icon color="#FF8A65" size="18px">mdi-delete-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>삭제</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div
        class="log"
        v-else
      >
        <p class="log-row"><span>메모가 없습니다.</span></p>
      </div>

      <!-- 메모 입력 영역 -->
      <div class="memo-area" :class="batch != null ? '' : 'mt-4'">
        <v-textarea
          :class="memoCategory.value"
          v-model="memo"
          background-color="#f2f2f2"
          :autofocus="true"
          placeholder="메모내용을 입력해주세요."
          dense
          auto-grow
          flat
          hide-details
          :color="memoCategory.color"
          outlined
          solo
          style="font-size: .9rem"
          ref="areaFocus"
        ></v-textarea>

        <div class="d-flex align-center justify-center mt-2">
          <v-radio-group
            dense
            row
            hide-details
            class="mt-0 pt-0 mr-10"
            v-model="memoCategory.value"
            v-for="(val, key) in categoryColor"
            :key="key"
          >
            <v-radio class="mr-2" :label="val.label"
              :value="val.value" :color="val.color"
              @change="updateMemoCategory(val)"></v-radio>
          </v-radio-group>
          <v-btn
            color="primary"
            elevation
            :disabled="memo.trim().length <= 0"
            @click="updateMemo()"
          >
            등록
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import comm from '@/util/comm';
import time from '@/util/time';

export default {
  name: 'DlgCustomerMemo',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    target: {
      type: Object,
      required: true,
    },
    func: {
      type: Function,
    },
    batch: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    comm,
    time,
    memo: '',
    groups: [],
    staffs: [],
    checked: [false],
    loading: false,
    textColor: '',
    memoCategory: { label: '리딩', value: 'leading', color: 'info' },
    categoryColor: [
      { label: '리딩', value: 'leading', color: 'info' },
      { label: '코인', value: 'coin', color: '#6aa33b' },
      { label: '기타', value: 'etc', color: 'accent' },
      { label: '레퍼럴', value: 'referral', color: 'error' },
    ],
    showStatusDialog: false,
    selectedStates: [],
    states: [],
    dbState: [
      { value: 1, name: '가망' },
      { value: 2, name: '차단' },
      { value: 3, name: '부재' },
      { value: 4, name: '재통' },
      { value: 5, name: '거절' },
      { value: 9, name: '인입' },
      { value: 10, name: '코인VIP' },
    ],
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      memoDlg: 'dialog/memo',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),

    // 초기화
    init() {
      this.$emit('update:show', false);
      this.memo = '';
      this.checked = [];
      this.showStatusDialog = false;
    },

    // 고객 상태 조회
    async getCustomerStatus() {
      try {
        this.loading = true;
        return new Promise((resolve) => {
          this.$socket.emit('users.customer.status.get', {
            id: this.target.id,
          }, (response) => {
            if (response.result === 'success') {
              // 기본값으로 모든 상태를 false로 설정
              this.states = this.dbState
                .filter((state) => state.value >= 0)
                .map((state) => ({
                  seq: state.value,
                  name: state.name,
                  bool: false,
                }));

              // response.items가 있고 길이가 있을 경우에만 상태 업데이트
              if (response.items && response.items.length > 0) {
                const itemStatus = JSON.parse(response.items[0].state || '[]');
                this.states = this.dbState
                  .filter((state) => state.value >= 0)
                  .map((state) => ({
                    seq: state.value,
                    name: state.name,
                    bool: itemStatus.includes(state.value),
                  }));
              }
              resolve(response);
            } else {
              // 에러 시에도 기본 상태 설정
              this.states = this.dbState
                .filter((state) => state.value >= 0)
                .map((state) => ({
                  seq: state.value,
                  name: state.name,
                  bool: false,
                }));
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${response.code})`]);
              resolve(null);
            }
            this.loading = false;
          });
        });
      } catch (error) {
        // 에러 시에도 기본 상태 설정
        this.states = this.dbState
          .filter((state) => state.value >= 0)
          .map((state) => ({
            seq: state.value,
            name: state.name,
            bool: false,
          }));
        this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${error.message})`]);
        this.loading = false;
        return null;
      }
    },

    // 고객 상태 업데이트
    async updateCustomerStatus() {
      try {
        this.loading = true;
        const saveState = this.states
          .filter((state) => state.bool)
          .map((state) => state.seq);

        return new Promise((resolve, reject) => {
          this.$socket.emit('users.customer.status.update', {
            id: this.target.id,
            status: saveState,
          }, (response) => {
            if (response.result === 'success') {
              this.alert(['success', '고객상태정보가 변경되었습니다.']);
              this.showStatusDialog = false;
              if (this.func) this.func();
              resolve(response);
            } else {
              reject(new Error(response.code));
            }
            this.loading = false;
          });
        });
      } catch (error) {
        this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${error.message})`]);
        this.loading = false;
        return null;// Ensure there's always a return value
      }
    },
    getDefaultData() {
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.$socket.emit('users.staffs.list.get', {
            page: 1,
            itemsPerPage: 0,
            sortBy: [],
            sortDesc: [],
            filters: [],
          }, (res) => {
            if (res.result === 'success') {
              this.groups = resp.items.groups;
              this.staffs = [];
              res.items.forEach((r) => {
                const groupName = comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
                this.staffs.push({
                  id: r.id,
                  fullName: `${groupName}${r.name}`,
                });
              });
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${res.code})`]);
              console.error(res);
              this.init();
            }
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.init();
        }
      });
    },
    // 기존 메서드들...
    makeCreatorToFullName(creator) {
      const row = this.staffs.find((r) => r.id === creator);
      return row?.id === undefined ? '시스템' : row.fullName;
    },

    updateMemo() {
      if (this.memo.trim().length > 300) {
        this.alert(['error', '최대 300자까지 등록가능합니다.']);
      } else if (this.batch == null) {
        const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;
        if (this.memoCategory.value === 'referral' && reg.exec(this.memo) !== null) {
          this.alert(['error', '레퍼럴의 경우 숫자만 입력 가능합니다.']);
        } else {
          this.progress(true);
          const memos = [...this.data];
          memos.unshift({
            memo: this.memo,
            creator: this.userInfo.id,
            date: time.makeUTCTime(),
            category: this.memoCategory.value,
          });

          this.$socket.emit('users.customer.update', {
            items: [{
              id: this.target.id,
              memo: JSON.stringify(memos),
            }],
          }, (resp) => {
            if (resp.result === 'success') {
              if (this.memoCategory.value === 'referral') {
                this.$socket.emit('users.customer.memo.referral.update', {
                  items: [{
                    id: this.target.id,
                    memo: this.memo,
                    manager: this.userInfo.id,
                  }],
                }, (response) => {
                  if (response.result === 'success') {
                    this.$emit('update:data', memos);
                    this.alert(['success', '메모가 등록되었습니다.']);
                    this.memo = '';
                    this.func();
                    this.progress(false);
                  } else {
                    this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${response.code})`]);
                    console.error(response);
                    this.loading = false;
                  }
                });
              } else {
                this.$emit('update:data', memos);
                this.alert(['success', '메모가 등록되었습니다.']);
                this.memo = '';
                this.func();
                this.progress(false);
              }
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
              this.progress(false);
            }
          });
        }
      } else {
        const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;
        if (this.memoCategory.value === 'referral' && reg.exec(this.memo) !== null) {
          this.alert(['error', '레퍼럴의 경우 숫자만 입력 가능합니다.']);
        } else {
          this.progress(true);
          this.$socket.emit('users.customer.memo.update', {
            items: this.batch,
            memo: this.memo,
            category: this.memoCategory.value,
          }, (resp) => {
            if (resp.result === 'success') {
              if (this.memoCategory.value === 'referral') {
                this.$socket.emit('users.customer.memo.referral.batch.update', {
                  items: this.batch,
                  memo: this.memo,
                  manager: this.userInfo.id,
                }, (response) => {
                  if (response.result === 'success') {
                    this.alert(['success', '메모가 등록되었습니다.']);
                    this.init();
                    this.func();
                    this.progress(false);
                  } else {
                    this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${response.code})`]);
                    console.error(response);
                    this.loading = false;
                  }
                });
              } else {
                this.alert(['success', '메모가 등록되었습니다.']);
                this.init();
                this.func();
                this.progress(false);
              }
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
              this.progress(false);
            }
          });
        }
      }
    },

    handleReferralUpdate(memos) {
      this.$socket.emit('users.customer.memo.referral.update', {
        items: [{
          id: this.target.id,
          memo: this.memo,
          manager: this.userInfo.id,
        }],
      }, (response) => {
        if (response.result === 'success') {
          this.handleSuccessfulUpdate(memos);
        } else {
          this.handleError(response);
        }
      });
    },
    handleBatchSuccess() {
      this.alert(['success', '메모가 등록되었습니다.']);
      this.init();
      this.func();
      this.progress(false);
    },

    handleError(resp) {
      this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
      console.error(resp);
      this.progress(false);
    },

    delMemo(target, index) {
      const func = (event) => {
        if (event.key === 'Enter' || event.type === 'click') {
          this.confirm({ show: false });
          const userId = target.id;
          this.$socket.emit('users.customer.memo.delete', { id: userId, memoSeq: index }, (resp) => {
            if (resp.result === 'success') {
              this.alert(['success', '삭제되었습니다.']);
              this.getcustomerMemo(target.id);
              this.func();
              this.progress(false);
            } else {
              this.handleError(resp);
            }
          });
          document.removeEventListener('keydown', func);
        }
      };

      this.confirm({
        show: true,
        func,
        msg: `삭제된 메모는 복구할수 없습니다.
          <br/><br/>정말 삭제할까요?`,
        btnText: '삭제',
        color: 'error',
      });

      document.addEventListener('keydown', func);
    },

    updateMemoCategory(category) {
      if (this.categoryColor.filter((e) => e.value === category.value)) {
        this.memoCategory = category;
        this.$refs.areaFocus.focus();
      }
    },

    filterCategory(item) {
      const filtered = this.categoryColor.filter((c) => c.value === item);
      return filtered.color;
    },

    getcustomerMemo(id) {
      this.progress(true);
      this.$socket.emit('users.customer.memo.get', { id }, (resp) => {
        if (resp.result === 'success') {
          this.$emit('update:data', resp.items[0].memo);
          this.progress(false);
        } else {
          this.handleError(resp);
        }
      });
    },

    deleteSelectedMemo() {
      const userId = this.target.id;
      const memo = [];
      for (let i = 0; i < this.checked.length; i += 1) {
        if (this.checked[i] === true) {
          memo.push(i);
        }
      }

      this.$socket.emit('users.customer.memo.selectDel', {
        id: userId,
        memoArray: memo,
      }, (resp) => {
        if (resp.result === 'success') {
          this.alert(['success', '삭제되었습니다.']);
          this.checked = [];
          this.getcustomerMemo(this.target.id);
          this.func();
          this.progress(false);
        } else {
          this.handleError(resp);
        }
      });
    },
    deleteAllMemo() {
      const func = () => {
        this.confirm({ show: false });
        const userId = this.target.id;
        this.$socket.emit('users.customer.memo.all.delete.option', {
          id: userId,
          option: 'option',
        }, (resp) => {
          if (resp.result === 'success') {
            this.alert(['success', '메모가 모두 삭제되었습니다.']);
            this.func();
            this.getcustomerMemo(this.target.id);
            this.progress(false);
          } else {
            this.handleError(resp);
          }
        });
      };

      this.confirm({
        show: true,
        func,
        msg: `삭제된 메모는 복구할수 없습니다.
          <br/><br/>정말 삭제할까요?`,
        btnText: '삭제',
        color: 'error',
      });
    },
    checkFuncPermission(funcName) {
      try {
        const userPermission = { ...this.userInfo.permission.cs.menu.customer.func };
        let result = false;
        if (
          userPermission[funcName] !== undefined
          && userPermission[funcName].bool === true
        ) result = true;
        return result;
      } catch (error) {
        console.error('checkFuncPermission error');
        return false;
      }
    },
  },

  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val === true) {
          this.getDefaultData();
          if (!this.batch) {
            this.getCustomerStatus();
          }
        }
      },
    },
    showStatusDialog(val) {
      if (val === true) {
        this.states = this.dbState
          .filter((state) => state.value >= 0)
          .map((state) => ({
            seq: state.value,
            name: state.name,
            bool: false,
          }));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.options {
  font-size: 0.9rem;
  padding: 0px 12px;
  min-height: 18px;
  cursor: pointer;
}

.status-dialog {
  .v-list {
    max-height: 400px;
    overflow-y: auto;
  }
}

.log-wrap {
  padding: 15px;

  .tit {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 10px;
    margin: 0;
  }

  .sub {
    font-size: 0.9rem;
    margin-top: -8px;
    margin-left: 4px;
    margin-bottom: 6px;
  }

  .log {
    max-height: 500px;
    overflow: auto;

    .log-row {
      background-color: #f2f2f2;
      padding: 10px;
      margin: 0;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 400px;

      & + .log-row {
        margin-top: 4px;
      }

      span {
        font-size: 0.9rem;
        color: #555;
        & + .info-box {
          margin-left: 40px;
        }
      }

      span.log-span {
        color: #262626;
        font-weight: 500;
        &.memo-span {
          white-space: pre-wrap;
          max-width: 400px;
        }
      }
    }

    .info-box {
      p {
        text-align: right;
        font-size: 0.8rem;
        margin: 0;
        color: #222;
        &.date {
          color: #888;
        }
      }
    }
  }

  .memo-area {
    min-width: 400px;
  }

  .leading {
    color: #2196F3 !important;
  }

  .coin {
    color: #6aa33b !important;
  }

  .etc {
    color: #AA6063 !important;
  }

  .referral {
    color: #FF5252 !important;
  }
}
</style>
